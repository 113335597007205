
import { Component, Vue } from 'vue-property-decorator'
import { apiDesignerLists, apiDesignerFrozen, apiDesignerUnfrozen, apiPicLists } from '@/api/shop'
import { RequestPaging } from '@/utils/util'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import LsDialog from '@/components/ls-dialog.vue'
@Component({
  components: {
    LsPagination,
    ExportData,
    LsDialog
  }
})
export default class Designer extends Vue {
  /** S Data **/
  form = {
    keyword: '' // 用户信息
  }

  picObj = {
    title: '',
    designer_id: ''
  }

  // 日期选择器数据
  timeForm = []

  // 设置用户标签弹出框
  dialogVisible = false
  // 选中用户标签
  labelValue = []
  // 用户选择框数据
  userLevelList = {}
  userLabelList = {}
  sourceList = {}
  // 分页查询
  pager: RequestPaging = new RequestPaging()
  pager2: RequestPaging = new RequestPaging()
  // 被选中的用户id
  multipleSelection = []
  dialogTableVisible = false
  /** E Data **/

  /** S Methods **/
  apiDesignerLists = apiDesignerLists // 传递给导出组件的api

  // 选中的用户触发事件
  handleSelectionChange (val: any) {
    this.multipleSelection = val
  }

  // 查询按钮
  query () {
    this.pager.page = 1
    this.getUserList()
  }

  // 获取用户列表数据
  getUserList () {
    this.pager.request({
      callback: apiDesignerLists,
      params: {
        ...this.form
      }
    })
  }

  openPic (item: any) {
    console.log(item)
    this.dialogTableVisible = true
    this.getPicList(item.id)
  }

  // 获取
  getPicList (id: any) {
    this.picObj.designer_id = id
    this.pager2.request({
      callback: apiPicLists,
      params: {
        ...this.picObj
      }
    })
  }

  // 重置按钮
  onReset () {
    this.form = {
      keyword: '' // 用户信息
    }
    this.timeForm = []
    this.getUserList()
  }

  // 打开设置用户标签弹窗
  openDialogVisible () {
    if (!this.multipleSelection) {
      this.$message.error('请选择用户!')
      return
    }
    if (this.multipleSelection.length <= 0) {
      this.$message.error('请选择用户!')
      return
    }
    this.dialogVisible = true
  }

  // 用户详情
  DetailsClick (item: any) {
    this.$router.push({
      path: '/shop/desginer_details',
      query: {
        id: item.id
      }
    })
  }

  // 冻结用户
  handleFrozen (userInfo: any) {
    const { status, id } = userInfo
    this.setUserInfo(id, status)
  }

  // 设置用户信息
  setUserInfo (id: number | string, value: any) {
    console.log(value)
    if (value == 1) {
      apiDesignerFrozen({
        id: id
      }).then(res => {
        this.getUserList()
      })
    } else {
      apiDesignerUnfrozen({
        id: id
      }).then(res => {
        this.getUserList()
      })
    }
  }

  // 批量冻结
  handleBatchFrozen (value: any) {
    const userIds = this.multipleSelection.map((item: any) => item.id)
    this.setUserInfo(userIds.join(','), value)
  }
  /** E Methods **/

  /** S Life Cycle **/
  created () {
    this.getUserList()
  }
  /** E Life Cycle **/
}
